<template>
  <v-app>
      <Mameluco/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Mameluco from '@/components/Mameluco.vue'

export default {
  name: 'MamelucoView',
  components: {
    Mameluco
  }
}
</script>

